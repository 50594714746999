@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'euclid-flex';
  src: url('./assets/fonts/EuclidFlex/EuclidFlex-Regular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'euclid-flex';
  src: url('./assets/fonts/EuclidFlex/EuclidFlex-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'wskf';
  src: url('./assets/fonts/WorldSkillsFR.otf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @apply font-euclid;
}

body,
html {
  overflow-x: hidden;
}

body {
  // @apply bg-lyon-purple text-white;
  -webkit-appearance: none;
  p {
    line-height: 155% !important;

    @apply pb-4;
  }

  p.default_line_height {
    line-height: 1 !important;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background: transparent;
    user-select: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  input {
    &::placeholder {
      text-transform: uppercase;
    }

    @apply text-sm;
  }
  input.select_radio {
    &::before {
      display: none;
      content: ' ' !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1rem;
      background-image: url('../public/images/check.svg');
      width: 18px;
      height: 17px;
    }
  }

  .booked {
    // display: none;
    background-color: #8b8b8b;
    background-image: url('../public/images/bookedCross.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-color: #8b8b8b;
  }
  .currentSlot{
    background-color: #3130ed;
    background-image: url('../public/images/bookedCross.svg');
    background-repeat: no-repeat;
    background-position: center;
    border-color: #3130ed;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    position: relative;

    &::before {
      display: none;
      content: '✔';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1rem;
    }

    &:checked {
      &::before {
        display: block;
      }
      @apply bg-lyon-blue border-2;
    }
  }

  .custom_select__control {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .custom_select__menu {
    @apply bg-lyon-blue;
  }

  .custom_select__option {
    background-color: transparent;
    border-bottom: 1px solid #ffffff;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
      @apply bg-lyon-placeholder_purple;
    }

    @apply text-sm;
  }

  .custom_select__indicator {
    color: #ffffff;

    &:hover {
      color: #ffffff;
    }
  }

  .select_set_color .custom_select__indicator {
    color: #3130ed !important ;
    &:hover {
      color: #3130ed !important;
    }
  }
  // .custom_select__input-container .select_set_color .css-qbdosj-Input {
  //   color: #3130ED !important;
  // }
  .select_set_color .custom_select__input-container,
  .select_set_color .custom_select__single-value {
    color: #3130ed !important;
  }
  .select_set_color svg {
    visibility: hidden;
  }
  .select_set_color .custom_select__indicator-separator {
    display: none;
  }
  .custom_select__input-container,
  .custom_select__single-value {
    color: #ffffff;

    @apply text-sm px-2;
  }

  .custom_select__placeholder {
    text-transform: uppercase;

    @apply text-lyon-placeholder_purple text-sm px-2;
  }

  .custom_select__input {
    @apply px-4;
  }

  .blob {
    position: absolute;
    width: 70vw;
    max-width: 777px;
    height: auto;
    z-index: -1;
    pointer-events: none;
  }

  .blob_form {
    position: absolute;
    max-width: 777px;
    height: auto;
    z-index: -1;
    pointer-events: none;
  }

  .container {
    max-width: 1100px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    @media (max-width: 768px) {
      padding: 0 15px !important;
    }
    section {
      position: relative;

      .inline {
        * {
          display: inline;
        }
      }
    }
  }
  .custom-tooltip [data-title] {
    position: relative;
  }

  .custom-tooltip [data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    top: 30px;
    display: inline-block;
    padding: 10px;
    border-radius: 2px;
    background: #fff;
    color: #3130ed;
    font-size: 16px;
    font-weight: 500;
    font-family: sans-serif;
    box-shadow: 4px 4px 11px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    width: 450px;
  }
  .rotate-conatiner {
    white-space: pre;
    background: rgb(158, 158, 158);
    transform: skewX(-42deg);
    position: relative;
    width: 106px;
    height: 232px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
  }
  .rotate-leftText {
    color: black;
    transform: rotate(-86deg);
    position: absolute;
  }

  .live-social-icon {
    path {
      fill: #ffffff;
    }

    &:hover {
      path {
        fill: #ffc4b0;
      }
    }
  }

  .open_mobile_nav {
    width: 50px;
    height: 50px;

    rect {
      fill: #ffffff;
    }

    &:hover {
      rect {
        fill: #ffc4b0;
      }
    }
  }

  .live-competition-links {
    a {
      position: relative;
      transition: 0.3s ease-in-out;
      transition-property: background-color, border-color, box-shadow, height,
        left, right, opacity;

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 30px;
        height: 2px;
        background-color: #ffc4b0;
        transition: 0.3s ease-in-out;
        transition-property: background-color, border-color, box-shadow, height,
          left, right, opacity;
        bottom: -2px;
        left: 0;
        right: 100%;
      }

      &:hover {
        &::after {
          right: 0;
        }
      }
    }
  }

  .header-close-btn {
    padding: 5px;

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover polygon {
      fill: #ffffff;
    }
  }
}

html {
  font-size: 13px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 17px;
  }
}
